<template>
  <div class="recommend">
    <div class="body">
      <div class="title">AI智能院校推荐</div>
      <div class="line"></div>
      <div class="info">

        <!-- 个人信息 -->
        <el-row class="info-data" type="flex" align="middle">
          <el-col :span="3">省份 <span>河南</span></el-col>
          <el-col :span="3">科类 <span>{{ is_wenli == 1 ? "文科" : "理科" }}</span></el-col>
          <el-col :span="3">分数 <span>{{ score }} 分</span></el-col>
          <el-col :span="4">位次 <span>{{ ranking }} 名</span></el-col>
          <el-col :span="7">
            批次：
            <el-select v-model="batch" style="width: 100px" size="mini" class="round_select" placeholder="请选择批次">
              <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button class="getCode" type="primary" @click="getSchoolNum" size="mini">查询推荐院校</el-button>
          </el-col>
        </el-row>
        <div class="icon">
          <div class="box" :class="{ act: act == 0 }" @click="click(0)">
            <el-row>
              <el-col :span="8">
                <div class="img img-1"></div>
              </el-col>
              <el-col :span="12">
                <div class="title">冲一冲</div>
                <div class="num">
                  <span>{{ NumList.first }}</span>所院校
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="box" :class="{ act: act == 1 }" @click="click(1)">
            <el-row>
              <el-col :span="8">
                <div class="img img-2"></div>
              </el-col>
              <el-col :span="13">
                <div class="title">稳一稳</div>
                <div class="num">
                  <span>{{ NumList.two }}</span>所院校
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="box" :class="{ act: act == 2 }" @click="click(2)">
            <el-row>
              <el-col :span="8">
                <div class="img img-3"></div>
              </el-col>
              <el-col :span="13">
                <div class="title">保一保</div>
                <div class="num">
                  <span>{{ NumList.three }}</span>所院校
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="box" :class="{ act: act == 3 }" @click="click(3)">
            <el-row>
              <el-col :span="8">
                <div class="img img-4"></div>
              </el-col>
              <el-col :span="13">
                <div class="title">垫一垫</div>
                <div class="num">
                  <span>{{ NumList.four }}</span>所院校
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="brief">
        <el-row>
          <el-col :span="1">
            <div></div>
          </el-col>
          <el-col :span="23">
            <li>
              1、AI智能院校推荐是依据您的分数及位次，综合历年院校投档数据，为您推荐冲、稳、保、垫的相关院校功能。推荐院校仅供参考，须结合自身情况进行最终填报
            </li>
            <li>
              2、2021-2023年各院校在投档分数，参考考试院及相关院校官网历年数据。
            </li>
            <li>
              3、在正式填报时，要以省考试院公布的最新招生计划及相关数据为准。
            </li>
          </el-col>
        </el-row>
      </div>
      <div class="collegeList" v-loading="loading">
        <CollegeList :scoreData="scoreData" v-for="(item, i) in list" :key="i" :item="item" style="margin-top: 20px">
        </CollegeList>
        <div class="bg" v-if="pageSize == 1">
          <div class="vip" @click="toVip">
            <img src="@/assets/img/recommend/vip.png" alt="">
            <p class="txtxxx"> 开通会员服务</p>
            <p class="txtxxx">查看全部信息</p>
            <p class="txtxxx">登陆后开通</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollegeList from "./components/CollegeList";
import { mapState } from "vuex";
//time: 2020/10/24
export default {
  name: "index",
  components: {
    CollegeList,
  },
  computed: {
    ...mapState(["login", "userInfo"]),
  },
  data() {
    return {
      batch: "1",
      is_wenli: 1,
      score: 0,
      NumList: [],
      list: [],
      pageSize: 1,
      act: 0,
      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "1" },
        { id: "3", name: "高职高专批", code: "3", status: "1" },
      ],
      scoreData: [],
      loading: false,
      level: 0
    };
  },
  created() {
    if (this.userInfo) {
      this.score = this.userInfo.score;
      this.is_wenli = this.userInfo.is_wenli;
      this.ranking = this.userInfo.ranking;
      if (this.userInfo.is_vip > 0) {
        this.pageSize = 100;
      }
    } else {
      this.pageSize = 1;
      this.score = this.$route.query.score;
      this.is_wenli = this.$route.query.is_wenli;
      this.ranking = this.$route.query.ranking;
    }
    if (this.is_wenli == 1) {
      if (this.score < 547) {
        this.batch = "2";
      }
      if (this.score < 465) {
        this.batch = "3";
      }
    } else {
      if (this.score < 514) {
        this.batch = "2";
      }
      if (this.score < 409) {
        this.batch = "3";
      }
    }
    this.getSchoolNum();
  },
  methods: {
    //获取推荐院校数量
    getSchoolNum() {
      let data = {
        score: this.score,
        is_wenli: this.is_wenli,
        batch: this.batch,
        ranking: this.ranking
      };
      this.$fecth.post("Recommend/getSchoolNum", data).then((res) => {
        this.NumList = res;
        this.level = res.level
        this.getSchoolList(0);
      });
    },

    //选择推荐类型
    click(e) {
      this.act = e;
      this.getSchoolList(e);
    },

    getSchoolList(e) {
      if (this.level == 0) {
        return false
      }
      this.loading = true;
      let data = {
        pageSize: this.pageSize,
        is_wenli: this.is_wenli,
        batch: this.batch,
        ranking: this.ranking,
        level: this.level + e - 1
      };
      this.$fecth.post("Recommend/getSchoolList", data).then((res) => {
        this.loading = false;
        this.list = res.list;
        this.scoreData = res.score_data;
      });
    },
    toVip() {
      if (this.userInfo) {
        this.$router.push("/goodsInfo?id=2");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.recommend {
  overflow: hidden;
  background: #e0dfdf;

  .body {
    background: white;
    padding: 10px 30px;
    width: 1140px;
    margin: 5px auto;

    .line {
      margin-top: 5px;
      border-bottom: 1px solid #3479f8;
    }
  }

  .info {
    display: inline-block;
    width: 100%;

    .info-data {
      padding: 30px 50px 5px 50px;

      span {
        border-radius: 40px;
        border: 1px solid #3479f8;
        color: #3479f8;
        padding: 2px 14px;
      }
    }

    .icon {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      letter-spacing: 2px;
    }

    .act {
      color: white;

      background: #3479f8;

      .img-1 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat 0 0 !important;
      }

      .img-2 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat -60px 0px !important;
      }

      .img-3 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat -120px 0px !important;
      }

      .img-4 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat -180px 0px !important;
      }
    }

    .icon .box {
      width: 25%;
      text-align: center;
      border: 1px solid #dfdfdf;
      vertical-align: center;

      .img {
        margin-top: 15px;
        margin-left: 50px;
        width: 40px;
        height: 60px;
      }

      .img-1 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat -180px -60px;
      }

      .img-2 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat -120px -60px;
      }

      .img-3 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat -60px -60px;
      }

      .img-4 {
        background: url("../../assets/img/recommend/rm_bg.png") no-repeat 0px -60px;
      }

      .title {
        margin-top: 16px;
      }

      .num span:first-child {
        color: red;
      }
    }

    .foot {
      text-align: center;
      color: #3479f8;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .brief {
    border: 1px solid #dfdfdf;
    line-height: 23px;
    font-size: 14px;
    padding: 20px;
  }
}

.vip {
  width: 200px;
  margin: 0 auto;
  position: relative;

  p {
    position: absolute;
  }

  p:nth-child(2) {
    top: 30px;
    left: 43px;
    color: #ef985a;
  }

  p:nth-child(3) {
    top: 57px;
    left: 43px;
    color: #ef985a;
  }

  p:nth-child(4) {
    top: 108px;
    left: 54px;
    color: #1787e0;
  }
}

.bg {
  padding-top: 10px;
  background: #13b0f8;
}
</style>

<style lang="less">
.round_select {
  .el-input__inner {
    border-radius: 30px;
  }
}
</style>