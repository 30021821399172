<template>
  <router-link :to="{
    path: '/College',
    query: { cid: item.cid, sid: item.school_id, degree: item.degree }
  }" target="_blank" class="college">
    <el-row>
      <el-col :span="3">
        <el-avatar :size="120" :src="item.logo" />
      </el-col>
      <el-col :offset="1" :span="18">
        <p class="row">
          <span class="name">{{ item.name }}</span>
          <span v-for="(tip, i) in item.school_tip_id" :key="i" class="tag">{{ tip }}</span>
        </p>
        <p class="row info">
          <span>类型：{{ item.type }}</span>
          <span v-if="item.school_belong">隶属：{{ item.school_belong }}</span>
          <span>办学性质：{{ item.nature }}</span>
          <span>属地：{{ item.city_name }}</span>
          <span v-if="item.school_id">在豫招生代号：{{ item.school_id }}</span>
        </p>
        <div class="hr">
        </div>
        <div class="score" v-if="scoreData[item.select_code + '2023']">
          <div v-if="scoreData[item.select_code + '2023']">
            <span>2023年</span>
            <span>最低分</span>
            <span class="red">{{ scoreData[item.select_code + '2023'].min_score }}</span>
            <span>位次</span>
            <span class="green">{{ scoreData[item.select_code + '2023'].ranking }}</span>
          </div>
          <div v-else></div>

          <div v-if="scoreData[item.select_code + '2022']">
            <span>2022年</span>
            <span>最低分</span>
            <span class="red">{{ scoreData[item.select_code + '2022'].min_score }}</span>
            <span>位次</span>
            <span class="green">{{ scoreData[item.select_code + '2022'].ranking }}</span>
          </div>
          <div v-else></div>

          <div v-if="scoreData[item.select_code + '2021']">
            <span>2021年</span>
            <span>最低分</span>
            <span class="red">{{ scoreData[item.select_code + '2021'].min_score }}</span>
            <span>位次</span>
            <span class="green">{{ scoreData[item.select_code + '2021'].ranking }}</span>
          </div>
          <div v-else></div>


        </div>
      </el-col>
    </el-row>
  </router-link>
</template>

<script>
export default {
  name: "CollegeList",
  props: ["item", "scoreData"],
  data() {
    return {
      att: 0,
    };
  },
  mounted() { },

};
</script>

<style lang="less" scoped>
.hr {
  width: 100%;
  height: 1px;
  background: #bdbcbc;
}

.college {
  display: block;
  padding: 20px 30px;
  border: 1px solid #dfdfdf;
  color: black;

  .row {
    height: 35px;
    line-height: 35px;

    span {
      margin-right: 20px;
    }

    &.info {
      width: 120%;
      color: #b8b8b8;
    }

    .name {
      font-size: 20px;
      color: #757575;
    }

    .tag {
      padding: 2px 14px;
      font-size: 12px;
      border-radius: 40px;
      border: 1px solid #afaeae;
      color: #afaeae;
      position: relative;
      top: -4px;
    }
  }

  .score {
    div {
      width: 260px;
    }

    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    span {
      margin-right: 8px;
    }

    .red {
      color: red;
    }

    .green {
      color: #3479f8;
    }
  }
}
</style>
